





















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ClockIcon from '@/app/ui/assets/icon_clock_fill.vue'
import { Utils } from '@/app/infrastructures/misc'
import Button from '@/app/ui/components/Button/index.vue'
import ChevronDownIcon from '@/app/ui/assets/chevron_down.vue'
import ChevronUpIcon from '@/app/ui/assets/chevron_up.vue'

@Component({
  components: {
    ClockIcon,
    Button,
    ChevronDownIcon,
    ChevronUpIcon,
  },
})
export default class TimePicker extends Vue {
  @Prop({default: undefined}) private value!: Date | undefined
  @Prop({default: 'Pilih Jam'}) private placeholder!: string
  @Prop({default: () => []}) private hourOptions!: string[] | number[]
  @Prop({default: () => []}) private minuteOptions!: string[] | number[]
  @Prop({default: false}) private disabled!: boolean
  @Prop({default: false}) private isError!: boolean
  @Prop({default: true}) private isInputPositionY!: boolean
  @Prop({default: true }) private isWithTimeZone!: boolean

  isPickerOpen = false
  selectedHour = ''
  selectedMinute = ''
  inputPositionY = 0
  tempValue: Date | null = null

  created(): void {
    this.setTimePreview()
  }

  get formatedTime(): string {
    if (!this.value) {
      return ''
    }

    return Utils.formatDateWithIDLocale(
      new Date(this.value).toISOString(),
      'HH:mm'
    )
  }

  get makeMinuteOptions(): Array<string | number> {
    return this.minuteOptions.length > 0 ? this.minuteOptions : Array.from({length: 60}, (_x, i) => i)
  }

  get timeZone(): string {
    return this.isWithTimeZone ? Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(new Date().toISOString(), 'Z')
    ) : ''
  }

  private openTimePicker(): void {
    if (this.isInputPositionY) {
      this.inputPositionY =
        (<HTMLElement>this.$refs.input).getBoundingClientRect().top +
        window.scrollY
    }
    if (!this.disabled) {
      this.isPickerOpen = true
      this.setTimePreview()
      this.scrollToElement()
    }
  }

  private closeTimePicker(): void {
    this.isPickerOpen = false
    this.setTimePreview()
  }

  private pad(value: number | string): string {
    if (typeof value === 'string') {
      value = parseInt(value)
    }
    return value < 10 ? '0' + value.toString() : value.toString()
  }

  private setTimePreview(): void {
    this.selectedHour = this.formatedTime.split(':')[0]
    this.selectedMinute = this.formatedTime.split(':')[1]
  }

  private onSelectTime(type: string, time: number | string): void {
    let newTime = this.pad(time)
    if (type === 'hour') {
      this.selectedHour = newTime
    } else {
      this.selectedMinute = newTime
    }
    this.tempValue = new Date(
      `${Utils.formatDateWithIDLocale(new Date().toISOString(), 'YYYY-MM-DD')} ${this.selectedHour}:${this.selectedMinute}`
    )
    this.scrollToElement()
  }

  private submitTimePicker(): void {
    let newDateValue = this.value || this.tempValue
    if (newDateValue) {
      newDateValue.setHours(
        parseInt(this.selectedHour),
        parseInt(this.selectedMinute)
      )
    }
    this.$emit('input', new Date(<Date>newDateValue))
    this.$emit('time', {
      hour: this.selectedHour,
      minute: this.selectedMinute,
      date: new Date(<Date>newDateValue)
    })
    this.$emit('update:modelValue')
    this.closeTimePicker()
  }

  private async scrollToElement(): Promise<void> {
    await this.$nextTick()
    const minuteEl = <HTMLElement>this.$refs.minuteEl
    const hourEl = <HTMLElement>this.$refs.hourEl

    const elHour: HTMLElement | null = hourEl.querySelector('.selected')

    let topPosHour = 1
    if(elHour) {
      topPosHour = elHour.offsetTop - 104
    }

    const elMins: HTMLElement | null = minuteEl.querySelector('.selected')

    let topPosMinute = 1
    if(elMins) {
      topPosMinute = elMins.offsetTop - 104
    }

    try {
      hourEl.scrollTo({top: topPosHour, left: 0, behavior: 'smooth'})
      minuteEl.scrollTo({top: topPosMinute, left: 0, behavior: 'smooth'})
    } catch (error) {
      return
    }
  }

  private onChangeTime(type: string, timeType: string): void {
    let selectedTime =
      timeType === 'hour' ? this.selectedHour : this.selectedMinute
    let timeOptions =
      timeType === 'hour' ? this.hourOptions : this.makeMinuteOptions
    let index = timeOptions.findIndex(hour => hour === parseInt(selectedTime))
    let tempIndex = 0
    let newTimeValue: string | number = ''

    if (type === 'prev') {
      tempIndex = index - 1
    } else {
      tempIndex = index + 1
    }

    newTimeValue = timeOptions[tempIndex]

    if (typeof newTimeValue !== 'undefined') {
      if (timeType === 'hour') {
        this.selectedHour = this.pad(newTimeValue)
      } else {
        this.selectedMinute = this.pad(newTimeValue)
      }
      this.scrollToElement()
    }
  }

  @Watch('value')
  onValueChange(value: Date): void {
    this.selectedHour = Utils.formatDateWithIDLocale(
      new Date(value).toISOString(),
      'HH'
    )
    this.selectedMinute = Utils.formatDateWithIDLocale(
      new Date(value).toISOString(),
      'mm'
    )
  }
}
