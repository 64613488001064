















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CheckBox from '../../components/CheckBox/index.vue'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'

export interface IData {
  name: string
  isChecked: boolean
  routeId: string
  isNotHidden: (keyword: string) => boolean
  setIsChecked: (state: boolean) => void
  setCheckedNested: (state: boolean) => void
  isDisabled: () => boolean
  children: Array<IData>
}

@Component({
  name: 'TreeList',
  components: {
    CheckBox,
    ExpandIcon,
    TreeList,
  },
})
export default class TreeList extends Vue {
  @Prop({ default: {} }) private data!: IData
  @Prop({ default: false }) private disabled!: boolean
  @Prop({ default: '{}' }) private meta!: string

  isOpen = false

  get isParent(): boolean {
    if (this.data.children?.length !== 0) {
      return true
    }
    return false
  }

  get metaData(): Record<string, string> {
    return JSON.parse(this.meta)
  }

  get isNotHidden(): boolean {
    if (this.metaData.keyword) {
      return this.data.isNotHidden(this.metaData.keyword)
    }

    return true
  }

  private onClickItem() {
    if (this.isParent) {
      this.isOpen = !this.isOpen
    }
  }

  private onClickCheckbox() {
    if (!this.disabled) {
      this.data.setCheckedNested(!this.data.isChecked)
    }
  }

  @Watch('data.children', { deep: true })
  setCheckboxState() {
    if (this.data.children) {
      let filteredData: Array<IData> = []

      this.data.children.forEach((item: IData) => {
        if (!item.isDisabled()) {
          filteredData.push(item)
        }
      })

      const states = [
        ...new Set(filteredData.map((item: IData) => item.isChecked)),
      ]

      // set isChecked state to false when child items state include false
      if (states.includes(false)) {
        this.data.setIsChecked(false)
      }

      // set isChecked state to true when all child state is true
      if (states.length === 1 && states[0] === true) {
        this.data.setIsChecked(true)
      }
    }
  }

  @Watch('metaData.keyword')
  setClose(): void {
    this.isOpen = false
  }
}
